<template>
    <div class="messageCore-main">
        <div class="main-list">
            <van-list
                v-model="loading"
                :finished="finished"
                
                :finished-text='followList.length==0 ? "" : $t("message.nomore")'
                @load="onLoad"
                >
                <div class="item-container flex" v-for="(item,index) in followList" :key="index" @click="goDetail(item)">
                    <div class="item-left">
                        <p class="name">{{item.userName}}</p>
                        <p class="ready-start">
                            {{$t("followUp.readystart")}}:
                        </p>
                        <!--  over--> 
                        <p class="conduct" :class="!item.isStart ? 'notStarted': !item.isEnd ? 'conduct': 'over'" >
                            {{ !item.isStart? $t("followUp.notStarted"): !item.isEnd ? $t('followUp.number',{num:item.startNum}):$t("followUp.over")}}
                        </p>
                    </div>
                    <div class="item-right flex">
                        <div class="follow-details flex">
                            <div class="details-txt flex">
                                <div class="txt-item">
                                    <p class="lable">{{$t("followUp.bindfollow")}}：</p>
                                    <p class="value">{{item.name}}</p>
                                </div>
                                <div class="txt-item">
                                    <p class="lable">{{$t("followUp.plantime")}}：</p>
                                </div>
                            </div>
                            <div class="details-circle">
                                <!--#E4E5E8  #BFBFBF  -->
                                <!--#E4E5E8 #0095FF -->
                                <my-circle :percentNum="item.cricle" :width="'2rem'" :circleColor="!item.isStart ? '#BFBFBF': !item.isEnd ?  '#FF671C': '#0095FF'" :circleback="!item.isStart ? '#E4E5E8': !item.isEnd ?  '#FFE9DE': '#0095FF'">
                                    <div class="schedule flex"  slot="container" :style="{'--color':!item.isStart ? '#BFBFBF': !item.isEnd ?  '#FF671C': '#0095FF'}">
                                        <p class="txt" :style="{'--color':!item.isStart ? '#BFBFBF': !item.isEnd ?  '#FF671C': '#0095FF'}">{{$t("followUp.schedule")}}</p>
                                        <p class="circleNum">{{item.cricle}}%</p>
                                    </div>
                                </my-circle>
                            </div>
                        </div>
                        <p class="time">{{getLocalDateTime(item.cdt)}}~{{getLocalDateTime(item.endTime)}}</p>
                    </div>
                    <p class="see-details">
                    </p>
                </div>
            </van-list>
            <div class="messageEmpty" v-if="followList == 0">
                <img src="@/assets/img/message-empty.png" alt="">
                <p class="txt">{{$t("followUp.nofollow")}}</p>
                <p class="desc">{{$t("followUp.tips")}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { followupQuery } from "@/api/index";
import { getLocalDateTime } from "@/utils/date";
import myCircle from "@/components/my_circle"
export default {
    components:{myCircle},
    data () {
        return {
            pageSize:10,
            pageNum:1,
            followList:[],
            getLocalDateTime,
            loading: false,
            finished: false,
        }
    },
    async created(){
        // const toast = this.$Toast.loading({
        //     duration: 0, // 持续展示 toast
        //     forbidClick: true,
        //     message: this.$t("message.loadingTxt")
        // });
        document.title = this.$t("followUp.name")
        // // await this.getMessageData();
        // toast.close();
    },
    methods:{
        getMessageData(){
            followupQuery({
                pageSize:this.pageSize,
                pageNum:this.pageNum
            }).then(res=>{
                this.loading = false;
                if(res.data.errorCode == 0){
                    for (let i = 0; i < res.data.data.data.length; i++) {
                        const item = res.data.data.data[i];
                        res.data.data.data[i].isStart = false;
                        res.data.data.data[i].isEnd = false;
                        res.data.data.data[i].startNum = 0;
                        res.data.data.data[i].cricle = 0;
                        res.data.data.data[i].endTime = res.data.data.data[i].cdt;
                        
                        for (let g = 0; g < res.data.data.data[i].nodes.length; g++) {

                            if(res.data.data.data[i].nodes[g].followUpNodeId != 0){
                                res.data.data.data[i].startNum ++;
                                res.data.data.data[i].isStart = true;
                                console.log(res.data.data.data[i].nodes[g].followUpNodeId);
                            }
                            res.data.data.data[i].endTime = res.data.data.data[i].cdt + ((res.data.data.data[i].nodes[g].nday-1) * 60 * 60 * 24 * 1000);
                        }
                        if(res.data.data.data[i].isStart){
                            res.data.data.data[i].cricle = parseInt(res.data.data.data[i].startNum / res.data.data.data[i].nodes.length * 100);
                        }

                        if(res.data.data.data[i].startNum == res.data.data.data[i].nodes.length){
                            res.data.data.data[i].isEnd = true;
                        }
                        // console.log(res.data.data.data[i].startNum);
                    }
                    if(this.pageNum == 1){
                        this.followList = res.data.data.data;
                    }else{
                        res.data.data.data.map(item=>{
                            this.followList.push(item)
                        })
                    }
                    if(res.data.data.data.length < this.pageSize){
                        this.finished = true;
                    }
                }
            })
        },
        goDetail(row){
            this.$router.push({
                path:"/followup/followdetails",
                query:{
                    followUpId:row.followUpId
                }
            })
        },
        onLoad(){
            if(!this.finished){
                this.loading = true;
                this.getMessageData();
                // this.loading = false;
                this.pageNum ++;
            }
        }
    }
}
</script>

<style lang="less" scoped>
.messageCore-main{
    min-height: 100%;
    background: #F5F6FA;
    padding:0 0.6rem 0.6rem 0.6rem;
    box-sizing: border-box;
    .main-list{
        overflow: hidden;
        .item-container{
            padding: 0.7rem 0.6rem 0.85rem 0.6rem;
            background: #fff;
            border-radius: 0.32rem;
            margin-top: 0.6rem;
            position: relative;
            .item-left{
                flex: 0 0 auto;
                padding-right: 0.8rem;
                margin-right: 0.5rem;
                border-right: 0.02rem  solid #979797;
                font-family: PingFangSC-Medium, PingFang SC;
                .name,.status{
                    font-size: 0.72rem;
                    font-weight: 500;
                    color: #303133;
                    margin-bottom: 0.6rem;
                    max-width: 3rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .ready-start{
                    font-size: 0.44rem;
                    line-height: 0.6rem;
                    
                    font-weight: 400;
                    color: #666666;
                }
                .conduct{
                    font-size: 0.72rem;
                    font-weight: 500;
                    color: #FF671C;
                    line-height: 1rem;
                }
                .notStarted{
                    color: #999999;
                }
                .over{
                    color: #0095FF;   
                }
            }
            .item-right{
                flex: 1;
                flex-direction: column;
                .follow-details{
                    flex: 1;
                    overflow: hidden;
                    .details-txt{
                        flex: 1;
                        margin-right: 0.5rem;
                        flex-direction: column;
                        justify-content: space-between;
                        .txt-item{

                            .lable{
                                font-size: 0.44rem;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #666666;
                                line-height: 0.6rem;
                            }
                            .value{
                                font-size: 0.52rem;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #303133;
                                overflow: hidden;
                                // text-overflow: ellipsis;
                                // white-space: nowrap;
                                display: -webkit-box;
                                vertical-align: ;
                                -webkit-line-clamp: 1;
                                line-clamp: 1;
                                -webkit-box-orient: vertical;
                                
                            }
                        }
                    }
                    .details-circle{
                        flex: 0 0 auto;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        .schedule{
                            color: var(--color);
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            .txt{
                                font-size: 0.52rem;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                // color: #BFBFBF;
                                line-height: 0.74rem;
                                // -webkit-background-clip: text;
                                // -webkit-text-fill-color: transparent;
                            }
                            .circleNum{
                                font-size: 0.4rem;
                            }
                        }
                    }
                }
                .time{
                    flex: 0 0 auto;
                    font-size: 0.52rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #303133;
                }
            }
            .see-details{
                position: absolute;
                width: 2.85rem;
                height: 0.72rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background: url("~@/assets/img/follow-see-detail-back.png") no-repeat;
                background-size: 100% 100%;
                right: 0;
                bottom: 0;

            }
        }
        /deep/ .van-loading {
            height: 2rem;
            line-height: 2rem;
            .van-loading__text,.van-loading__spinner {
                font-size: 0.6rem;
            }
        }
    }
    .messageEmpty{
        height: 97vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img{
            width: 3.2rem;
            height: auto;
            margin-bottom: 0.8rem;
        }
        .txt{
            font-size: 0.64rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
        }
        .desc{
            font-size: 0.52rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
        }
    }
}
</style>